import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EItemFungibleType, IDatabaseItem, IShopItem } from 'interface';
import { RootStateType } from 'store';
import { shopApi } from './shop.api';
import { expToken, expTokenItem } from 'utils/items';
import { CUSTOMIZATION_BOTTLES, CUSTOMIZATION_POWDERS } from 'app/constants';

interface IShopStateItem {
  item: IShopItem | null;
  type: 'item' | 'goods';
  itemId: number | null;
}

interface IInitialState {
  items: IDatabaseItem[];
  shopItem: IShopStateItem;
}

const initialState: IInitialState = {
  items: [expToken],
  shopItem: {
    item: expTokenItem,
    type: 'item',
    itemId: 0,
  },
};

export const shopSlice = createSlice({
  name: 'itemSlice',
  initialState,
  reducers: {
    setShopItem: (state, action: PayloadAction<Partial<IShopStateItem>>) => {
      state.shopItem = { ...state.shopItem, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(shopApi.endpoints.fetchItems.matchFulfilled, (state, action: PayloadAction<IDatabaseItem[]>) => {
      state.items = action.payload;
    });
  },
});

export const { setShopItem } = shopSlice.actions;

export const getShopItem = (state: RootStateType) => state.shop.shopItem;
export const getItems = (state: RootStateType) => state.shop.items;
export const getCustomizationItems = (state: RootStateType) =>
  state.shop.items.filter(
    (i) => i?.fungibleType && CUSTOMIZATION_BOTTLES.concat(CUSTOMIZATION_POWDERS).includes(i.fungibleType as EItemFungibleType)
  );

export default shopSlice.reducer;
