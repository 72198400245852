import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { tokenService } from 'services/token.service';
import { authSlice, RootStateType, store } from 'store';
import { Mutex } from 'async-mutex';

const baseURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000/api';
const mutex = new Mutex();

export const arrayParamsSerializer = (data: Record<string, string[] | string>) => {
  const params = new URLSearchParams();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const str of data[key]) {
        params.append(key, str);
      }
    } else {
      params.append(key, data[key].toString());
    }
  }

  return params.toString();
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = tokenService.getAccessToken();
    const lang = (getState() as RootStateType).app.currentLanguage;
    if (!!token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    if (lang) headers.set('Accept-Language', lang);
    return headers;
  },
  paramsSerializer: arrayParamsSerializer,
});
export const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if ((result.error?.status as any) === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = tokenService.getRefreshToken();
        if (!refreshToken) {
          store.dispatch(authSlice.actions.setIsAuth(false));
          return result;
        }
        const refreshResult: any = await baseQuery({ url: 'auth/refresh', method: 'POST', body: { refreshToken } }, api, extraOptions);
        if (refreshResult.data) {
          // Retry the initial query
          tokenService.setAccessToken(refreshResult.data.accessToken);
          tokenService.setRefreshToken(refreshResult.data.refreshToken);
          result = await baseQuery(args, api, extraOptions);
        } else {
          await baseQuery({ url: 'auth/logout', method: 'POST', body: { refreshToken } }, api, extraOptions);
          tokenService.setRemoveTokens();
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
