import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { authApi } from './auth.api';
import { profileApi } from '../profile/profile.api';

interface IInitialState {
  isAuth: boolean;
  isShowSuccessAuthModal: boolean;
}

const initialState: IInitialState = {
  isAuth: false,
  isShowSuccessAuthModal: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsShowSuccessAuthModal: (state, action: PayloadAction<boolean>) => {
      state.isShowSuccessAuthModal = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getUserData.matchFulfilled, (state, _) => {
      state.isAuth = true;
    });
    builder.addMatcher(authApi.endpoints.loginWithMetaMask.matchFulfilled, (state, _) => {
      state.isAuth = true;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, _) => {
      state.isAuth = true;
    });
    builder.addMatcher(profileApi.endpoints.activateUser.matchFulfilled, (state, _) => {
      state.isShowSuccessAuthModal = true;
    });
    builder.addMatcher(authApi.endpoints.logOut.matchFulfilled, (state, _) => {
      state.isAuth = false;
    });
  },
});

export const { setIsShowSuccessAuthModal, setIsAuth } = authSlice.actions;

export const getIsAuth = (state: RootStateType) => state.auth.isAuth;
export const getIsShowSuccessAuthModal = (state: RootStateType) => state.auth.isShowSuccessAuthModal;

export default authSlice.reducer;
