import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'store';

export const useGetCourseGroups = () => {
  const { displayedName } = useSelector(getAppSettings);

  const { favouritesKey, ECourseGroup, partnerOriginals, nativeNamesObjByFilterNameKey, filterNamesByNameKey, groupsNames, groupsFilterNames } =
    useMemo(() => {
      const favouritesKey = 'favourites';

      const ECourseGroup = {
        PROGRAMMING: 'programming courses',
        BITCOINCRYPTOCURRENCY: 'bitcoin & cryptocurrency',
        ORIGINALS: 'originals',
        MARKETING: 'social media skills',
        WEB3: 'web3',
        AI: 'AI & Automation',
      };

      const partnerOriginals = `${displayedName} ${ECourseGroup.ORIGINALS}`;

      const nativeNamesObjByFilterNameKey = {
        [favouritesKey]: favouritesKey,
        [ECourseGroup.PROGRAMMING]: ECourseGroup.PROGRAMMING,
        [ECourseGroup.BITCOINCRYPTOCURRENCY]: ECourseGroup.BITCOINCRYPTOCURRENCY,
        [ECourseGroup.ORIGINALS]: partnerOriginals,
        [ECourseGroup.MARKETING]: ECourseGroup.MARKETING,
        [ECourseGroup.WEB3]: ECourseGroup.WEB3,
      };
      const filterNamesByNameKey = {
        [favouritesKey]: favouritesKey,
        [ECourseGroup.PROGRAMMING]: ECourseGroup.PROGRAMMING,
        [ECourseGroup.BITCOINCRYPTOCURRENCY]: ECourseGroup.BITCOINCRYPTOCURRENCY,
        [partnerOriginals]: ECourseGroup.ORIGINALS,
        [ECourseGroup.MARKETING]: ECourseGroup.MARKETING,
        [ECourseGroup.WEB3]: ECourseGroup.WEB3,
      };

      const groupsNames = Object.values(nativeNamesObjByFilterNameKey);
      const groupsFilterNames = Object.values(filterNamesByNameKey);
      return {
        favouritesKey,
        ECourseGroup,
        partnerOriginals,
        nativeNamesObjByFilterNameKey,
        filterNamesByNameKey,
        groupsNames,
        groupsFilterNames,
      };
    }, [displayedName]);

  return {
    ECourseGroup,
    groupsNames,
    groupsFilterNames,
    favouritesKey,
    nativeNamesObjByFilterNameKey,
    filterNamesByNameKey,
    partnerOriginals,
  };
};
