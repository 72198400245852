import React, { useEffect, useRef } from 'react';

type Props = {
  targetRef: React.RefObject<HTMLDivElement> | null;
  zIndex: number;
  blurAmount?: number;
};
// note: component is bad for components that are going to move/scroll :\
export const useAddBlurredBg = ({ targetRef, zIndex, blurAmount = 16 }: Props) => {
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleSet = () => {
      if (targetRef?.current && innerRef.current) {
        const { x, y, width, height } = targetRef.current.getBoundingClientRect();

        innerRef.current.style.width = `${width}px`;
        innerRef.current.style.height = `${height}px`;
        innerRef.current.style.left = `${x}px`;
        innerRef.current.style.top = `${y}px`;
        innerRef.current.style.borderRadius = targetRef.current.style.borderRadius;
      }
    };
    handleSet();

    window.addEventListener('scroll', handleSet);
    window.addEventListener('resize', handleSet);
    return () => {
      window.removeEventListener('scroll', handleSet);
      window.removeEventListener('resize', handleSet);
    };
  }, [targetRef]);

  return <div style={{ zIndex, position: 'fixed', backdropFilter: `blur(${blurAmount}px)` }} ref={innerRef} />;
};
