class TokenService {
  static getRefreshToken() {
    return localStorage.getItem('refresh-token');
  }
  static getAccessToken() {
    return localStorage.getItem('access-token');
  }
  static setRefreshToken(token: string) {
    return localStorage.setItem('refresh-token', token);
  }
  static setAccessToken(token: string) {
    return localStorage.setItem('access-token', token);
  }
  static setRemoveTokens() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  }
}

export const tokenService = TokenService;
