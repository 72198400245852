import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';

interface IInitialState {
  isFriendFinder: boolean;
}

const initialState: IInitialState = {
  isFriendFinder: false,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setIsFriendFinder: (state, action: PayloadAction<boolean>) => {
      state.isFriendFinder = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setIsFriendFinder } = modalsSlice.actions;

export const getIsFriendFinder = (state: RootStateType) => state.modals.isFriendFinder;

export default modalsSlice.reducer;
