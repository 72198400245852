import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import { useTimer } from './useTimer';

interface IProps {
  actionWhenProceeding?: () => any;
}

export const useUserEnergyLevel = ({ actionWhenProceeding }: IProps = {}) => {
  const {
    exp: {
      energy,
      energyPerHour,
      limit: { maxEnergy },
    },
  } = useSelector(getUserData);
  const [energyLevel, setEnergyLevel] = useState(energy);
  const { seconds, minutes, startAgainHandler } = useTimer(0, 5);
  const countdown = minutes !== 0 || seconds !== 0 ? `${minutes}:${seconds}` : null;
  const energyPerSecond = energyPerHour / 3600;
  const energyPersentage = Math.floor((energyLevel / maxEnergy) * 100);
  const energyIncome = (energyPerSecond * 5).toFixed(2);
  const filledUp = maxEnergy === energyLevel;

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     console.log({
  //       time: dayjs().format('hh:mm:ss'),
  //       currentEnergy: energyLevel.toFixed(3),
  //       recovRate: `${(energyPerSecond * 5).toFixed(3)}/5sec`,
  //       energyPersentage,
  //       energyLevel: energyLevel.toFixed(3),
  //     });
  //   }
  // }, [seconds, energyLevel, energyPerSecond, energyPersentage]);

  useEffect(() => {
    setEnergyLevel(energy);
  }, [energy]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!countdown && !!startAgainHandler && !filledUp) {
      timeout = setTimeout(() => {
        startAgainHandler();
        if (energyLevel + energyPerSecond * 5 <= maxEnergy) {
          actionWhenProceeding && actionWhenProceeding();
          setEnergyLevel(energyLevel + energyPerSecond * 5);
        }
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [seconds, minutes, startAgainHandler, countdown, energy, energyPerSecond, filledUp, maxEnergy, energyLevel, actionWhenProceeding]);

  return { filledUp, energyIncome, countdown, energyPersentage, energyLevel };
};
