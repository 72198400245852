import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IChestRaw } from 'interface';

export const chestsApi = createApi({
  reducerPath: 'chestsApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getChests: builder.query<IChestRaw[], void>({
      query: () => {
        return {
          url: 'chests.json',
        };
      },
    }),
  }),
});

export const { useGetChestsQuery } = chestsApi;
