import { ICourse, ICourseFull, ICourseNoChapters, IResultCourse } from 'interface/courses';
import { customFetchBase } from 'store/utils';
import {
  IAnswerBody,
  IAnswerResponse,
  IAnswerResponseError,
  ICompleteSlideRequest,
  ICompleteSlideResponse,
  ICourseForCompletionFetchResult,
} from './types';
import { ILeftOffLesson } from 'components/pages/study/courses/left-off-lesson';
import { createApi } from '@reduxjs/toolkit/query/react';

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  // refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  tagTypes: ['Course', 'StartedCourses'],
  endpoints: (builder) => ({
    getFullCourse: builder.query<ICourse, string>({
      query: (courseId) => {
        return {
          url: `/course/full/${courseId}`,
        };
      },
      keepUnusedDataFor: 100,
    }),
    getPartialCourse: builder.query<any, void>({
      query: (courseId) => {
        return {
          url: `/course/${courseId}`,
        };
      },
    }),
    getAllCourses: builder.query<any, void>({
      query: () => {
        return {
          url: `/course`,
        };
      },
    }),
    getCourseForCompletion: builder.query<ICourseForCompletionFetchResult, string>({
      query: (courseId: string) => ({ url: `/course/for-completion/${courseId}` }),
    }),
    getCourseByName: builder.query<ICourseNoChapters, string>({
      query: (courseName: string) => ({ url: `/course/by-name/${courseName}` }),
    }),

    addCourseToUser: builder.mutation<{ resultCourse: IResultCourse; startedCourse: ICourseFull; firstTime?: boolean }, { courseId: string }>({
      query: (data) => {
        return {
          url: `/course/add/to-user/${data.courseId}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['StartedCourses'],
    }),
    completeSlide: builder.mutation<ICompleteSlideResponse, ICompleteSlideRequest>({
      query: (data) => {
        return {
          url: `/result-slide/finish`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['Course'],
    }),
    checkAnswer: builder.mutation<IAnswerResponse | IAnswerResponseError, IAnswerBody>({
      query: (data) => {
        return {
          url: `/answer/check-answer`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    getLeftOffLesson: builder.query<{ leftOffLesson: ILeftOffLesson }, void>({
      query: () => {
        return {
          url: `/result-lesson/last-lesson`,
        };
      },
      keepUnusedDataFor: 100,
    }),
    getLeftOffLessonByCourseId: builder.query<{ leftOffLesson: ILeftOffLesson }, string>({
      query: (courseId) => {
        return {
          url: `/result-lesson/last-lesson/${courseId}`,
        };
      },
    }),
    getResultCourseByCourseId: builder.query<IResultCourse, { courseId: string }>({
      query: (courseId) => ({
        url: `/result-course/by-course-id/full/${courseId}`,
      }),
    }),
    // dev stuff
    flushCourse: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/result-course/flush/me`,
          method: 'PUT',
          body,
        };
      },
    }),
    flushMyCourses: builder.mutation<any, void>({
      query: () => {
        return {
          url: `/result-course/flush/me/all`,
        };
      },
      invalidatesTags: ['Course'],
    }),
    getStartedCourses: builder.query<any, any>({
      query: (body) => {
        return {
          url: `/result-course/started`,
          method: 'PUT',
          body,
        };
      },
      providesTags: ['StartedCourses'],
    }),
    // Students count

    getStudentsCountByCourse: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-course/students-count/by-course-id/${id}`,
      }),
    }),
    getStudentsCountByChapter: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-chapter/students-count/by-chapter-id/${id}`,
      }),
    }),
    getStudentsCountByLesson: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-lesson/students-count/by-lesson-id/${id}`,
      }),
    }),
    getStudentsCountBySlide: builder.query<{ students: number }, string>({
      query: (id) => ({
        url: `/result-slide/students-count/by-slide-id/${id}`,
      }),
    }),
    getCourseByAuthorId: builder.query<any, any>({
      query: (id) => ({
        url: `/course/by-author/${id}`,
      }),
    }),
  }),
});
export const {
  useLazyGetAllCoursesQuery,
  useLazyGetFullCourseQuery,
  useLazyGetCourseByNameQuery,
  useCompleteSlideMutation,
  useLazyGetPartialCourseQuery,
  useAddCourseToUserMutation,
  useCheckAnswerMutation,
  useLazyGetLeftOffLessonQuery,
  useLazyGetLeftOffLessonByCourseIdQuery,
  useFlushCourseMutation,
  useFlushMyCoursesMutation,
  useLazyGetResultCourseByCourseIdQuery,
  useLazyGetCourseForCompletionQuery,
  useLazyGetStartedCoursesQuery,
  useLazyGetStudentsCountByCourseQuery,
  useLazyGetStudentsCountByChapterQuery,
  useLazyGetStudentsCountByLessonQuery,
  useLazyGetStudentsCountBySlideQuery,
  useLazyGetCourseByAuthorIdQuery,
  useGetCourseByAuthorIdQuery,
} = coursesApi;
