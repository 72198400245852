import React from 'react';

import { default as circle } from 'assets/images/loader-ellipse--mini.svg';
import { default as check } from 'assets/icons/check-outline-rounded-mighty.svg';

import styles from './styles.module.css';
import clsx from 'clsx';
import { Logo } from 'shared/utils';

interface IProps {
  className?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

export default function MightyLoaderMini({ className = '', isLoading = true, style }: IProps) {
  return (
    <div style={style} className={clsx(styles.container, { [className]: !!className })}>
      {isLoading ? (
        <>
          <Logo containerClassName={styles.logo} width={'40%'} />
          <img src={circle} alt="circle" className={styles.circle} />
        </>
      ) : (
        <img src={check} alt="check" className={styles.check} />
      )}
    </div>
  );
}
