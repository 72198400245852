import { useEffect, useReducer } from 'react';
import { RESULT_STATUSES } from 'app/constants';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import { IChapterFull } from 'interface/courses';

interface State {
  lessonsLength: number;
  lessonsCompletedLength: number;
  chaptersLength: number;
  chaptersCompetedLength: number;
}

const useGetCourseProgressChapters = ({ courseId, chapters }: { courseId: string; chapters: (string | IChapterFull)[] }) => {
  const [state, updateState] = useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), {
    lessonsLength: 0,
    lessonsCompletedLength: 0,
    chaptersLength: 0,
    chaptersCompetedLength: 0,
  });

  const { resultCourses = [] } = useSelector(getUserData);

  useEffect(() => {
    if (resultCourses.length > 0) {
      const chapsWithLessons = chapters.filter((chap) => typeof chap !== 'string') as IChapterFull[];
      const lessons = chapsWithLessons.flatMap((chap) => chap.lessons);
      const resultCourse = resultCourses.find((rc) => rc.courseId === courseId);
      if (resultCourse) {
        const completedChapters = resultCourse.resultChapters.filter(
          (rChap) => chapters.map((c) => (typeof c === 'string' ? c : c._id)).includes(rChap.chapterId) && rChap.status === RESULT_STATUSES.FINISHED
        );
        const resultLessons = resultCourse.resultChapters.flatMap((chap) => chap.resultLessons);
        const completedLessons = resultLessons.filter((resLes) => resLes.status === RESULT_STATUSES.FINISHED);

        updateState({
          chaptersCompetedLength: completedChapters.length,
          lessonsCompletedLength: completedLessons.length,
          lessonsLength: lessons.length,
        });
      }
      updateState({ chaptersLength: chapters.length, lessonsLength: lessons.length });
    }
  }, [courseId, chapters, resultCourses]);

  const { chaptersCompetedLength, chaptersLength, lessonsCompletedLength, lessonsLength } = state;
  let total, completed;
  if (chaptersLength === 1) {
    total = lessonsLength;
    completed = lessonsCompletedLength;
  } else {
    total = chaptersLength;
    completed = chaptersCompetedLength;
  }

  return { total, completed };
};

export default useGetCourseProgressChapters;
