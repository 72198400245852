import { FastAverageColor, FastAverageColorOptions } from 'fast-average-color';
import checkImage from 'utils/checkImage';

export default async function getAverageColorByUrl(pic: string, options?: FastAverageColorOptions) {
  const defaultColor = 'rgba(255,255,255,1)';
  const fac = new FastAverageColor();
  const image = await checkImage(pic);
  let result = '';

  if (image.status === 'ok') {
    result = await fac
      .getColorAsync(image.path, { ...options, crossOrigin: 'anonymous' })
      .then((color) => color.rgba)
      .catch()
      .finally(() => defaultColor);
  } else {
    result = defaultColor;
  }
  fac.destroy();
  return result;
}
