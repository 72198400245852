import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { authApi } from '../auth';
import { LANGS, LOCAL_STORAGE_KEYS, EMenuScreens, THEMES } from 'app/constants';
import i18next from 'i18next';
import { TPanels } from 'components/library/navbar/UserMenu/types';
import { appApi } from './app.api';
import { ISiteSettings } from 'interface';

export type TLoadingVariant = 'global' | 'default';

interface IInitialState {
  isInit: boolean;
  currentLanguage: LANGS;
  currentTheme: THEMES;
  isMainMenu: boolean;
  isSidebar: boolean;
  mainMenuScreen: EMenuScreens;
  pageIsLoaded: boolean;
  smallPageIsLoading: boolean;
  activeUserMenuPanel: TPanels;
  settings: ISiteSettings;
}
// изначальный стейт (при первой загрузке приложения)
const initialState: IInitialState = {
  isInit: false,
  currentLanguage: LANGS.RU,
  currentTheme: THEMES.DARK,
  isMainMenu: false,
  isSidebar: false,
  mainMenuScreen: EMenuScreens.SETTINGS,
  pageIsLoaded: false,
  smallPageIsLoading: false,
  activeUserMenuPanel: 'none',
  settings: {
    displayedName: 'blank',
    originalName: 'blank',
    partnerId: 1,
    discordLink: '',
    contactLink: '',
    globalLoaderLink: '',
    logos: {
      xlarge: '',
      large: '',
      regular: '',
      small: '',
      xsmall: '',
    },
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitApp: (state, action: PayloadAction<boolean>) => {
      state.isInit = action.payload;
    },
    setCurrentLanguage: (state, action: PayloadAction<LANGS>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, action.payload);
      i18next.changeLanguage(action.payload);
      state.currentLanguage = action.payload;
    },
    setCurrentTheme: (state, action: PayloadAction<THEMES>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, action.payload);
      state.currentTheme = action.payload;
    },
    setCurrentThemeWithoutSaving: (state, action: PayloadAction<THEMES>) => {
      state.currentTheme = action.payload;
    },
    setIsMainMenu: (state, action: PayloadAction<{ isMainMenu: boolean; screen?: EMenuScreens }>) => {
      state.isMainMenu = action.payload.isMainMenu;
      state.mainMenuScreen = action.payload.screen ?? EMenuScreens.PROFILE;
    },
    setIsSidebar: (state, action: PayloadAction<boolean>) => {
      state.isSidebar = action.payload;
    },
    setPageIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.pageIsLoaded = action.payload;
    },
    setActiveUserMenuPanel: (state, action: PayloadAction<TPanels>) => {
      if (state.activeUserMenuPanel === action.payload) {
        state.activeUserMenuPanel = 'none';
      } else {
        state.activeUserMenuPanel = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, _) => {
      state.isInit = true;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, (state, _) => {
      state.isInit = true;
    });
    builder.addMatcher(appApi.endpoints.getSettings.matchFulfilled, (state, action) => {
      state.settings = action.payload;
    });
  },
});
export const {
  setIsInitApp,
  setCurrentLanguage,
  setCurrentTheme,
  setCurrentThemeWithoutSaving,
  setIsMainMenu,
  setIsSidebar,
  setPageIsLoaded,
  setActiveUserMenuPanel,
} = appSlice.actions;

const getIsInitForSelector = (state: RootStateType) => state.app.isInit;
const getAppSettingsForSelector = (state: RootStateType) => state.app.settings;
const getCurrentLanguageForSelector = (state: RootStateType) => state.app.currentLanguage;
const getCurrentThemeForSelector = (state: RootStateType) => state.app.currentTheme;
const getIsMainMenuForSelector = (state: RootStateType) => state.app.isMainMenu;
const getMainMenuScreenForSelector = (state: RootStateType) => state.app.mainMenuScreen;
const getIsSidebarForSelector = (state: RootStateType) => state.app.isSidebar;
const getPageIsLoadedForSelector = (state: RootStateType) => state.app.pageIsLoaded;
const getActiveUserMenuPanelForSelector = (state: RootStateType) => state.app.activeUserMenuPanel;

export const getIsInit = createSelector(getIsInitForSelector, (isInit) => isInit);
export const getAppSettings = createSelector(getAppSettingsForSelector, (settings) => settings);
export const getCurrentLanguage = createSelector(getCurrentLanguageForSelector, (currentLanguage) => currentLanguage);
export const getCurrentTheme = createSelector(getCurrentThemeForSelector, (currentTheme) => currentTheme);
export const getIsMainMenu = createSelector(getIsMainMenuForSelector, (isMainMenu) => isMainMenu);
export const getMainMenuScreen = createSelector(getMainMenuScreenForSelector, (mainMenuScreen) => mainMenuScreen);
export const getIsSidebar = createSelector(getIsSidebarForSelector, (isSidebar) => isSidebar);
export const getPageIsLoaded = createSelector(getPageIsLoadedForSelector, (pageIsLoaded) => pageIsLoaded);
export const getActiveUserMenuPanel = createSelector(getActiveUserMenuPanelForSelector, (activeUserMenuPanel) => activeUserMenuPanel);
export default appSlice.reducer;
