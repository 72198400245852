import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getCurrentAvatarShape } from 'store';
import hexToRgba from 'hex-to-rgba';
import { motion } from 'framer-motion';

import { IColor } from 'components/pages/menu/screens/profile/components/ChangerList/types/types';
import { defaultCustomization } from 'app/constants';

import * as Styled from './styles';

interface IProps {
  className?: string;
  nickname: string | undefined;
  avatar: string | undefined;
  isOnline?: boolean;
  isOnlineMarker?: boolean;
  isFramed?: boolean;
  customization?: any;
  animation?: any;
  smallGlow?: boolean;
}

export default function AvatarPic({
  nickname = 'Rising Phoenix',
  avatar = '',
  isOnline = false,
  isOnlineMarker = false,
  className = '',
  isFramed = false,
  customization = defaultCustomization,
  animation,
  smallGlow = false,
}: IProps) {
  const nick = avatar ? nickname : nickname.toUpperCase().split(' ');
  const [fontSize, setFontSize] = useState<number>(18);
  const theme = useSelector(getCurrentTheme);
  const shapeGiven = useSelector(getCurrentAvatarShape);
  const shape = customization?.avatarShape ?? shapeGiven;

  const getMeasuredFontSize = useCallback((node: HTMLDivElement) => {
    if (!!node) {
      const { width } = node.getBoundingClientRect();
      setFontSize(width ? Math.floor(width / 3) : 18);
    }
  }, []);

  function getBoxShadow(color: 'default' | IColor) {
    if (color && color !== 'default') {
      return { boxShadow: `0 0 ${smallGlow ? 10 : 20}px ${smallGlow ? 1 : 10}px ${hexToRgba(customization?.glow ?? '', 0.7)}` };
    }
    return { boxShadow: 'none' };
  }

  function currentGlow() {
    if (customization?.glow) {
      return getBoxShadow(customization?.glow);
    }
    return getBoxShadow(customization?.glow);
  }

  function currentAvatar() {
    if (avatar) {
      return (
        <Styled.AvatarImage
          as={motion.img}
          initial={animation && animation.initial}
          animate={animation && animation.animate}
          className={`avatar-image avatar--${shape} ${theme}`}
          src={avatar}
          alt={`${nickname} avatar`}
          style={currentGlow()}
        />
      );
    }
    return (
      <Styled.AvatarImage
        as={motion.div}
        initial={animation && animation.initial}
        animate={animation && animation.animate}
        style={currentGlow()}
        className={`avatar-noImage avatar--${shape} ${theme}`}
      >
        <span className={'span'} style={{ fontSize }}>
          {nick[0].slice(0, 1)} {nick[nick.length - 1].slice(0, 1)}
        </span>
      </Styled.AvatarImage>
    );
  }

  return (
    <Styled.Container
      className={clsx({
        [className]: !!className,
        framed: isFramed,
      })}
      ref={getMeasuredFontSize}
    >
      {currentAvatar()}
      <Styled.Marker className={clsx(theme, { online: isOnline, hidden: !isOnlineMarker })} />
      <Styled.Frame
        className={clsx(`avatar--${shape}`, {
          hidden: !isFramed,
          //  premium: isPremium
        })}
      />
    </Styled.Container>
  );
}
