import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';

export const useGetMultipliedExp = () => {
  const {
    exp: {
      limit: { expMulti },
    },
  } = useSelector(getUserData);
  const [expMultiplier, setMultiplier] = useState(expMulti);

  useEffect(() => {
    setMultiplier(expMulti);
  }, [expMulti]);

  const getMultipliedExp = (id: number, quantity: number) => {
    if (id === 0) {
      return Math.floor(quantity * expMultiplier);
    }
    return quantity;
  };
  return { expMultiplier, getMultipliedExp };
};
