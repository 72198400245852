import { createApi } from '@reduxjs/toolkit/query/react';
import {
  EChestFungibleType,
  IDatabaseItem,
  IDatabaseItemQuantified,
  IDigitalsRes,
  IEvent,
  IPaymentItem,
  IPremiumPurchaseBody,
  IShopItem,
  IUserExp,
} from 'interface';
import { customFetchBase } from 'store/utils';
import { expToken } from 'utils/items';
import { IActivationResponse, ICreateTransferRequest, ICreateTransferResponce, IDebitCreationData } from './types';

export const shopApi = createApi({
  reducerPath: 'shopApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  tagTypes: ['Shop', 'Exp'],
  endpoints: (builder) => ({
    fetchShop: builder.query<IShopItem[], { page?: number; limit?: number }>({
      query: (body) => ({
        url: '/items/shop',
        params: body,
      }),
      providesTags: ['Shop'],
    }),
    fetchShopItemById: builder.query<IShopItem, number>({
      query: (id) => ({
        url: `/items/shop/${id}`,
      }),
    }),
    fetchShopItemByGoodsId: builder.query<IShopItem, number>({
      query: (id) => ({
        url: `/items/shop/goods/by-id`,
        params: { id, quantity: false },
      }),
    }),
    fetchItems: builder.query<IDatabaseItem[], void>({
      query: () => ({
        url: '/items',
      }),
      transformResponse: (items: IDatabaseItem[]) => [expToken, ...items],
    }),
    buyItem: builder.mutation<{ newEvents: IEvent[]; exp: IUserExp }, IPaymentItem>({
      query: (data) => ({
        url: '/payments/item',
        method: 'POST',
        body: data,
      }),
    }),
    openChest: builder.mutation<
      { newEvents?: IEvent[]; exp: IUserExp; drop: IDatabaseItemQuantified[]; dev: any },
      { fungibleType: EChestFungibleType }
    >({
      query: (data) => ({
        url: '/payments/open-chest',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Exp'],
    }),
    buyPremium: builder.mutation<any, IPremiumPurchaseBody>({
      query: (data) => ({
        url: '/payments/premium',
        method: 'POST',
        body: data,
      }),
    }),
    activateItem: builder.mutation<IActivationResponse, number>({
      query: (id) => ({
        url: `/items/shop/activate/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Exp'],
    }),
    createTransferRequest: builder.mutation<ICreateTransferResponce, ICreateTransferRequest>({
      query: (data) => ({
        url: '/partner/nft/transfer-requests/create',
        method: 'POST',
        body: data,
      }),
    }),
    getDigitalsUser: builder.query<IDigitalsRes, void>({
      query: () => ({
        url: '/partner/digitals/activated/me',
        method: 'GET',
      }),
    }),
    sendDebitDataToBackend: builder.mutation<any, IDebitCreationData>({
      query: (body) => ({
        url: '/credit-card/create-checkout/premium',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyFetchShopQuery,
  useLazyFetchItemsQuery,
  useLazyFetchShopItemByIdQuery,
  useBuyItemMutation,
  useLazyFetchShopItemByGoodsIdQuery,
  useActivateItemMutation,
  useOpenChestMutation,
  useCreateTransferRequestMutation,
  useGetDigitalsUserQuery,
  useSendDebitDataToBackendMutation,
} = shopApi;
