import { ESlideTypes, IAnswer, ISlide } from './types';

export const generateAnswersBySlideType = (type: ESlideTypes) => {
  const answers: IAnswer[] = [];
  const rightAnswers: IAnswer[] = [];

  if (type === ESlideTypes.TEST) {
    answers.push({ text: 'Answer example' });
    rightAnswers.push({ text: 'Answer example' });
  } else if (type === ESlideTypes.MULTICHOICE) {
    answers.push({ text: 'Answer example 1' }, { text: 'Answer example 2' });
    rightAnswers.push({ text: 'Answer example 1' }, { text: 'Answer example 2' });
  } else if (type === ESlideTypes.TYPEANSWER) {
    answers.push({ text: 'Answer example 1' });
    rightAnswers.push({ text: 'Answer example 1' });
  } else if (type === ESlideTypes.FILLBLOCKS || type === ESlideTypes.FILLSPACES) {
    answers.push({ text: 'true' }, { text: 'true' }, { text: 'false' });
    rightAnswers.push({ text: 'true' }, { text: 'true' }, { text: 'false' });
  } else if (type === ESlideTypes.FILLSPACES_CODE) {
    answers.push({ text: 'world' });
    rightAnswers.push({ text: 'world' });
  } else if (type === ESlideTypes.FILLBLOCKS_CODE) {
    answers.push({ text: 'world' });
    rightAnswers.push({ text: 'world' });
  }

  return { answers, rightAnswers };
};

export const generateExampleNodes = (type: ESlideTypes) => {
  if (type === ESlideTypes.FILLBLOCKS_CODE || type === ESlideTypes.FILLSPACES_CODE) {
    return JSON.stringify({
      tag: 'div',
      attributes: { contenteditable: 'true' },
      children: [
        { tag: 'div', attributes: {}, children: [{ tag: '__text', text: 'Example template', attributes: {}, children: [] }], text: '' },
        { tag: 'div', attributes: {}, children: [{ tag: 'br', attributes: {}, children: [], text: '' }], text: '' },
        { tag: 'div', attributes: {}, children: [{ tag: '__text', text: '<script>', attributes: {}, children: [] }], text: '' },
        {
          tag: 'div',
          attributes: {},
          children: [
            { tag: '__text', text: '  console.log("Hello ', attributes: {}, children: [] },
            {
              tag: 'span',
              attributes: {
                class: 'inline-answers-v2__answer-badge',
                'data-mighty-answer': 'world',
                'data-mighty-type': 'mighty-answer-slot',
                contenteditable: 'false',
              },
              children: [{ tag: '__text', text: 'world', attributes: {}, children: [] }],
              text: '',
            },
            { tag: '__text', text: '!");', attributes: {}, children: [] },
          ],
          text: '',
        },
        { tag: 'div', attributes: {}, children: [{ tag: '__text', text: '</script>', attributes: {}, children: [] }], text: '' },
      ],
      text: '',
    });
  }

  if (type !== ESlideTypes.FILLBLOCKS && type !== ESlideTypes.FILLSPACES) {
    return '';
  }

  return JSON.stringify({
    tag: 'div',
    attributes: {
      class: 'mighty-inline-answers',
      contenteditable: 'true',
    },
    children: [
      {
        tag: '__text',
        text: '\n    ',
        attributes: {},
        children: [],
      },
      {
        tag: 'div',
        attributes: {},
        children: [
          {
            tag: '__text',
            text: 'Example template',
            attributes: {},
            children: [],
          },
        ],
        text: '',
      },
      {
        tag: 'div',
        attributes: {},
        children: [
          {
            tag: 'br',
            attributes: {},
            children: [],
            text: '',
          },
        ],
        text: '',
      },
      {
        tag: 'div',
        attributes: {},
        children: [
          {
            tag: '__text',
            text: '1 === 1 is ',
            attributes: {},
            children: [],
          },
          {
            tag: 'span',
            attributes: {
              class: 'mighty-answer',
              'data-mighty-answer': 'true',
              'data-mighty-type': 'mighty-answer-slot',
              contenteditable: 'false',
            },
            children: [
              {
                tag: '__text',
                text: 'true',
                attributes: {},
                children: [],
              },
            ],
            text: '',
          },
        ],
        text: '',
      },
      {
        tag: 'div',
        attributes: {},
        children: [
          {
            tag: '__text',
            text: '2 === 2 is ',
            attributes: {},
            children: [],
          },
          {
            tag: 'span',
            attributes: {
              class: 'mighty-answer',
              'data-mighty-answer': 'true',
              'data-mighty-type': 'mighty-answer-slot',
              contenteditable: 'false',
            },
            children: [
              {
                tag: '__text',
                text: 'true',
                attributes: {},
                children: [],
              },
            ],
            text: '',
          },
        ],
        text: '',
      },
      {
        tag: 'div',
        attributes: {},
        children: [
          {
            tag: '__text',
            text: '2 === 1 is ',
            attributes: {},
            children: [],
          },
          {
            tag: 'span',
            attributes: {
              class: 'mighty-answer',
              'data-mighty-answer': 'false',
              'data-mighty-type': 'mighty-answer-slot',
              contenteditable: 'false',
            },
            children: [
              {
                tag: '__text',
                text: 'false',
                attributes: {},
                children: [],
              },
            ],
            text: '',
          },
        ],
        text: '',
      },
      {
        tag: '__text',
        text: '\n',
        attributes: {},
        children: [],
      },
    ],
    text: '',
  });
};

export const genereteSlideTypeBySlideType = (type: ESlideTypes) => {
  const { answers, rightAnswers } = generateAnswersBySlideType(type);

  const slideTypeObj: ISlide['slideType'] = {
    name: 'test',
    answers,
    rightAnswers,
    isAnyCorrect: false,
  };

  if (type === ESlideTypes.FILLBLOCKS || type === ESlideTypes.FILLSPACES) {
    slideTypeObj.nodes = '';
  }
};
