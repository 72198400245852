import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getItems } from 'store';
import clsx from 'clsx';
import { getItemByKey } from 'utils';

import { ReactComponent as Spinner } from 'assets/icons/spinner--pie.svg';
import { ReactComponent as Complete } from 'assets/icons/check-outline-rounded-mighty.svg';

import { LazyImage } from 'shared/ui/lazyImage';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import styles from './styles.module.css';

const defaultColors = {
  backgrounds: {
    light: {
      message: 'radial-gradient(135.13% 316.55% at 152.33% -90.78%, rgba(101, 116, 247, 0) 0%, rgba(99, 113, 243, 0.4) 100%), white',
    },
    dark: {
      message:
        'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, #292B39 -1.86%, #282A39 100.22%)',
    },
  },
  color: {
    light: 'var(--basic-blue)',
    dark: 'var(--basic-blue)',
  },
};

interface IProps {
  amount: number;
  itemId?: number;
  type: 'receive' | 'purchase';
  status: 'complete' | 'pending';
  fullItem?: {
    name: string;
    image: string;
  };
  isMighty?: boolean;
  isLoading?: boolean;
}

const Message = ({ amount, itemId = 1, status, type, isMighty = false, isLoading }: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const items = useSelector(getItems);
  const item = getItemByKey('id', itemId, items);

  const { color, backgrounds } = defaultColors,
    { name, image } = item;

  // Renders different text depending on action circumstances
  function renderUpperText() {
    if (type === 'receive') {
      if (status === 'complete') return <p className={styles.text}>Congratulations!</p>;
      if (status === 'pending')
        return (
          <p className={styles.text}>
            Receiving{' '}
            <span style={{ color: color[theme] }}>
              {amount} {name}...
            </span>
          </p>
        );
    }
    if (status === 'complete') return <p className={styles.text}>Purchase Successful!</p>;
    return (
      <p className={styles.text}>
        Purchasing{' '}
        <span style={{ color: color[theme] }}>
          {amount} {name}...
        </span>
      </p>
    );
  }

  function renderLowerText() {
    if (type === 'receive') {
      if (status === 'complete')
        return (
          <p className={styles.text}>
            You have received{' '}
            <span style={{ color: color[theme] }}>
              {amount} {name}
            </span>
          </p>
        );
      if (status === 'pending') return <span className={styles.text}>Please Wait...</span>;
    }
    if (status === 'complete')
      return (
        <p className={styles.text}>
          You have bought{' '}
          <span style={{ color: color[theme] }}>
            {amount} {name}
          </span>
        </p>
      );
    return <span className={styles.text}>Please Wait...</span>;
  }

  const loader = isMighty ? <MightyLoaderMini className={styles.loader} isLoading={isLoading} /> : <Spinner className="spinner" />;

  return (
    <div className={styles.container}>
      <div className={clsx(styles.message, { [styles.mighty]: isMighty })} style={{ background: backgrounds ? backgrounds[theme].message : '' }}>
        <div className={styles.imageWrap}>
          <LazyImage src={image} alt={`${name} img`} />
        </div>
        <div className={styles.columnWrap}>
          <div className={styles.upperText}>{renderUpperText()}</div>
          <div className={styles.lowerText}>{renderLowerText()}</div>
        </div>
        <div className={styles.icon}>
          {status === 'complete' ? <Complete className={clsx(styles.complete, { [styles.mightyIcon]: isMighty })} /> : loader}
        </div>
      </div>
    </div>
  );
};

export default Message;
