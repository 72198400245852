import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
// import { MoralisProvider } from 'react-moralis';
import { persistor, store } from 'store';
import './i18n';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalPageAnimation } from './shared/utils/GlobalPageAnimation/GlobalPageAnimation';
import { PersistGate } from 'redux-persist/integration/react';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ 'app/app'));

// const appID = process.env.REACT_APP_MORALIS_APPLICATION_ID ?? '';
// const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL ?? '';
//@ts-ignore
if (window.Telegram.WebApp) {
  //@ts-ignore
  window.Telegram.WebApp.expand();
}

ReactDOM.render(
  // <MoralisProvider environment="browser" initializeOnMount appId={appID} serverUrl={serverUrl}>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_TON_MANIFEST_URL}>
        <React.StrictMode>
          <GlobalPageAnimation />
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </React.StrictMode>
      </TonConnectUIProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
