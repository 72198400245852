import { LANGS } from 'app/constants';

export enum ESpaceTag {
  Web3 = 'Web 3.0',
  RWA = 'RWA',
  Infrastructure = 'Infra',
  DAO = 'DAO',
  VC = 'VC',
  Wallet = 'Wallet',
  Exchange = 'Exchange',
  AIAutomation = 'AI',
  DeFi = 'DeFi',
  NFT = 'NFT',
  GameFi = 'GameFi',
  KOL = 'KOL',
  Tools = 'Tools',
  Community = 'Community',
  Other = 'Other',
}

export interface ISpaceDesc {
  language: LANGS;
  title: string;
}

export interface ISpace {
  _id: string;
  users: ISpaceUser[];
  name: string;
  link: string;
  description: ISpaceDesc[];
  image: string;
  imageThumb: string;
  tags: ESpaceTag[];
  isVerified: boolean;
}

export interface ISpaceShort {
  name: string;
  imageThumb: string;
}

export interface IMySpacesResponseItem extends Omit<ISpace, 'users'> {
  users: IMySpaceRespUser[];
}

export interface ISpaceUser {
  roles: ESpaceRoles[];
  userId: {
    _id: string;
    nickname: string;
    avatar: string;
  };
  isSub?: boolean;
}

export interface IMySpaceRespUser extends Omit<ISpaceUser, 'userId'> {
  userId: string;
}

export enum ESpaceRoles {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}
