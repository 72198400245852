import { useEffect, useRef } from 'react';

type Props = {
  handler(): void;
};

export const useObserver = ({ handler }: Props) => {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handler, { threshold: 0.1 });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef.current]);

  return { observerRef };
};
