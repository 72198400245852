import { useEffect } from 'react';
import { ICourseProcessedRewarded, ICourseRewarded } from 'interface/courses';

type Props = {
  setDisplayedCourses: (displayedCourses: (ICourseRewarded | ICourseProcessedRewarded)[]) => void;
  courses: (ICourseRewarded | ICourseProcessedRewarded)[];
  groups: { name: string; filterName: string }[];
};

export const useHandleGroups = ({ setDisplayedCourses, courses, groups }: Props) => {
  useEffect(() => {
    if (groups.length === 0) return setDisplayedCourses(courses);

    const orderedCourses = courses.filter((course) => {
      if (groups.map((i) => i.filterName).includes('favourites') && groups.length === 1) {
        return true;
      }
      return course.groups.some((group: string) => groups.some((g) => g.filterName === group));
    });
    setDisplayedCourses(orderedCourses);
  }, [groups.length, courses.map((i) => i.name).join('')]);
};
