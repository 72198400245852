import { ISlide } from './types';

export const prepareDuplicateDto = (slide: ISlide) => {
  const { slideType, ...slideData } = slide;
  const { typeOfSlide, type, ...slideTypeDto } = slideType;

  const dto = { ...slideData, slideType: slideTypeDto };

  return dto;
};

export const prepareUpdateDto = (slide: ISlide) => {
  const { lessonId, slideType, ...slideData } = slide;
  const { typeOfSlide, type, ...slideTypeDto } = slideType;

  const dto = { ...slideData, slideType: slideTypeDto };

  return dto;
};

export const prepareCreateDto = (slide: ISlide) => {
  const { _id, ...dto } = slide;
  return dto;
};

export const updateSlideField_SlideType = (slide: ISlide, slideTypeField: Partial<ISlide['slideType']>): ISlide => {
  return {
    ...slide,
    slideType: {
      ...slide.slideType,
      ...slideTypeField,
    },
  };
};
