import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { ILeaderboardsResponse, TSpaceLeaderboardResponse } from './leaderboard.types';

export const leaderboardApi = createApi({
  reducerPath: 'leaderboardApi',
  // refetchOnFocus: true,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getLeaderboardUsers: builder.query<ILeaderboardsResponse, void>({
      query: (query) => {
        return {
          url: '/leaderboards',
        };
      },
    }),
    getLeaderboardUsersBySpace: builder.query<TSpaceLeaderboardResponse, string>({
      query: (spaceName) => ({
        url: `/leaderboards/space/${spaceName}`,
      }),
    }),
  }),
});

export const { useLazyGetLeaderboardUsersQuery, useLazyGetLeaderboardUsersBySpaceQuery } = leaderboardApi;
