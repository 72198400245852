import { EAuthMethods } from 'interface';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';

export const useGetAuthMethodsData = () => {
  const { authMethods = [] } = useSelector(getUserData);

  const getIEmail = () => (authMethods ? authMethods.find((i) => [EAuthMethods.Email, EAuthMethods.Google].includes(i.method))?.token : undefined);

  return { getIEmail };
};
