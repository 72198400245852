import { motion } from 'framer-motion';
import { FC, Fragment, useEffect } from 'react';
import { useDisplay } from 'hooks';
import clsx from 'clsx';

import { ReactComponent as Bg1 } from '../assets/bg1-min.svg';
import { ReactComponent as Bg2 } from '../assets/bg2-min.svg';

import { Logo } from 'shared/utils/logo';

import styles from '../styles.module.scss';

interface IProps {
  isFixed?: boolean;
  theme?: 'red' | 'base';
}

const wrapVariants = {
  initial: (isMobile: boolean) => ({
    opacity: isMobile ? 1 : 0,
  }),
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const PageLoader: FC<IProps> = ({ isFixed = false, theme = 'base' }) => {
  const { isMobile } = useDisplay();

  useEffect(() => {
    document.documentElement.style.overflowY = 'hidden';

    return () => {
      document.documentElement.style.overflowY = 'unset';
    };
  }, []);

  return (
    <motion.div
      className={clsx(styles.wrap, styles.isPagePreload, isFixed ? styles.fixed : null, styles[theme])}
      variants={wrapVariants}
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      custom={isMobile}
    >
      <motion.div className={styles.end}>
        <div className={styles.content}>
          <motion.div className={styles.mightyLogo}>
            <Logo imageClassName={styles.mightyLogoImg} withAnimation withLabel />
          </motion.div>
        </div>
        {!isMobile && theme === 'base' && (
          <Fragment>
            <Bg1 className={styles.bg1} />
            <Bg1 className={styles.bg2} />
            <Bg2 className={styles.bg3} />
          </Fragment>
        )}
      </motion.div>
    </motion.div>
  );
};
