import { useTonConnectUI } from '@tonconnect/ui-react';
import { ROUTES } from 'app/constants';
import { useNavigate } from 'react-router-dom';
import { useLogOutMutation } from 'store';

export const useLogout = () => {
  const [tonConnectUI] = useTonConnectUI();
  const [logOut] = useLogOutMutation();
  const navigate = useNavigate();

  const logOutHandler = async () => {
    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
    logOut().unwrap().catch(console.error);
    // .then(() => {
    //   navigate(ROUTES.COURSES);
    // });
  };
  return logOutHandler;
};
