import { createApi } from '@reduxjs/toolkit/query/react';
import { INFTokenResponse } from 'interface/venly';
import { customFetchBase } from 'store/utils';

export const venlyApi = createApi({
  reducerPath: 'venlyApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getNTFs: builder.query<INFTokenResponse, void>({
      query: (walletId) => {
        return {
          url: `venly/nfts/${walletId}`,
        };
      },
    }),
    getMissingMetadataNTFs: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `venly/nfts/specific`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getShopNFTS: builder.query<any, void>({
      query: () => {
        return {
          url: `venly/nfts/master`,
        };
      },
    }),
  }),
});

export const { useLazyGetNTFsQuery, useLazyGetShopNFTSQuery, useGetMissingMetadataNTFsMutation } = venlyApi;
