import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';
import { getUserData, useLazyGetMyInventoryQuery, usePremiumExpirationNotifMutation } from 'store';
import { getBadges, blankEffect } from './data';
import { IProps } from './types';
import { TItemFungibleTypeForEffects } from 'interface';
import dayjs from 'dayjs';

export const useGetEffectsData = ({ isBadges = false }: IProps = {}) => {
  const [createExpiredPremiumNotif] = usePremiumExpirationNotifMutation();
  const [refetch] = useLazyGetMyInventoryQuery();
  const {
    exp: {
      limit: { maxEnergy, effects: userEffects },
      energy,
    },
  } = useSelector(getUserData);

  const [effects, setEffects] = useState<IExpEffectWidgetData[]>([]);

  useEffect(() => {
    const energyPercent = Math.ceil((energy / maxEnergy) * 100);
    // эффекты юзера + его показатель энергии + статус его премиума = effects
    setEffects(() => {
      const effects: IExpEffectWidgetData[] = [];
      let filteredByExpiration = [];

      if (process.env.NODE_ENV === 'development') {
        const timezoneOffsetInMilisecs = new Date().getTimezoneOffset() * 60 * 1000;
        filteredByExpiration = userEffects
          .filter((effect) => dayjs(effect.expiredAt).valueOf() + timezoneOffsetInMilisecs > dayjs().valueOf())
          .map((effect) => ({
            ...effect,
            expiredAt: dayjs(effect.expiredAt).add(timezoneOffsetInMilisecs, 'milliseconds').format(),
          }));
      } else {
        filteredByExpiration = userEffects.filter((effect) => dayjs(effect.expiredAt).valueOf() > dayjs().valueOf());
      }

      // const prem = filteredByExpiration.find((item) => item.name === 'Premium');
      // if (!prem) {
      //   effects.push({
      //     effect: {
      //       ...blankEffect,
      //       name: 'No premium',
      //       itemFungibleType: 'no-premium',
      //       description: 'Bruh...',
      //     },
      //     type: 'no-premium',
      //     amount: 100,
      //   });
      // }
      effects.push({
        effect: {
          ...blankEffect,
          name: energyPercent > 5 ? 'energy' : 'no-energy',
          itemFungibleType: energyPercent > 5 ? 'energy' : 'no-energy',
        },
        type: energyPercent > 5 ? 'energy' : 'no-energy',
        amount: energyPercent,
        badges: isBadges ? getBadges(blankEffect) : [],
      });

      const test: IExpEffectWidgetData[] = filteredByExpiration.map((eff) => ({
        type: eff.itemFungibleType,
        effect: eff,
        badges: getBadges(eff),
      }));

      return [...effects, ...test];
    });
  }, [energy, maxEnergy, userEffects, isBadges]);

  const handleTimeout = (type: TItemFungibleTypeForEffects) => () => {
    refetch();
    setEffects((prev) => {
      const newEffects = prev.filter((i) => i.effect.name !== type);
      const prems: TItemFungibleTypeForEffects[] = ['emperor-key', 'emperor-badge', 'emperor-crown', 'scholar-coin'];
      if (prems.includes(type)) {
        const noPremium: IExpEffectWidgetData = {
          effect: {
            ...blankEffect,
            name: 'no-premium',
            description: "You've got no premium sub:/",
          },
          type: 'no-premium',
          amount: 100,
        };
        createExpiredPremiumNotif();
        return [noPremium, ...newEffects];
      }
      return newEffects;
    });
  };

  return { effects, handleTimeout };
};
