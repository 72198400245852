import clsx from 'clsx';
import { FC, Fragment, memo } from 'react';
import { useDisplay } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'store';

import { ReactComponent as Bg1 } from './assets/bg1-min.svg';
import { ReactComponent as Bg2 } from './assets/bg2-min.svg';

import { IPageAnimation } from './model';
import { usePageAnimation } from './hooks';
import { Logo } from '../logo';

import styles from './styles.module.scss';

export const PageAnimation: FC<IPageAnimation> = memo(({ isLoading, setAnimateIsExit, saveMemory = false, isPagePreload = false }) => {
  const { isMobile } = useDisplay();
  const { globalLoaderLink, displayedName } = useSelector(getAppSettings);

  const {
    animationComplete,
    firstFrameIsExit,
    currentVariantWrap,
    currentVariantStart,
    currentVariantEnd,
    currentVariantImage,
    handleOnAnimateComplete,
  } = usePageAnimation({ isLoading, setAnimateIsExit, saveMemory });

  if (animationComplete || displayedName === 'blank') {
    return <></>;
  }

  return (
    <motion.div
      custom={isLoading}
      className={clsx(styles.wrap, isPagePreload ? styles.isPagePreload : '')}
      variants={currentVariantWrap}
      animate={'animate'}
      exit={'exit'}
      onAnimationComplete={handleOnAnimateComplete}
    >
      <AnimatePresence>
        {isLoading && (
          <motion.div
            custom={firstFrameIsExit}
            className={clsx(styles.start)}
            variants={currentVariantStart}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
          >
            <img src={globalLoaderLink} alt={displayedName} />
          </motion.div>
        )}
      </AnimatePresence>

      {!isLoading && (
        <motion.div className={styles.end} variants={currentVariantEnd} initial={'initial'} animate={'animate'} exit={'exit'}>
          <div className={styles.content}>
            <motion.div className={styles.mightyLogo} variants={currentVariantImage} initial={'initial'} animate={'animate'} exit={'exit'}>
              <Logo imageClassName={styles.mightyLogoImg} withAnimation withLabel size="small" />
            </motion.div>
          </div>
          {!isMobile && (
            <Fragment>
              <Bg1 className={styles.bg1} />
              <Bg1 className={styles.bg2} />
              <Bg2 className={styles.bg3} />
            </Fragment>
          )}
        </motion.div>
      )}
    </motion.div>
  );
});
